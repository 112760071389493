<template>
  <div class="split-code-wrap" :class="{ centered: isCentered, active: isFocused, error: state === 'error' }">
    <div class="visually-hidden">
      <label for="hiddenInput">TOTP input</label>
      <input
        id="hiddenInput"
        ref="hiddenInput"
        v-model="code"
        v-maskito="maskitoOptions"
        class="split-code-input"
        name="hiddenInput"
        type="text"
        autocomplete="one-time-code"
        :maxlength="maxLength"
        @focus="isFocused = true"
        @blur="isFocused = false"
      />
    </div>

    <div
      class="split-code"
      :class="{ [type]: true }"
      @click="onClick"
    >
      <div class="split-code--part">
        <div
          v-for="(item, i) in firstPart"
          :key="i"
          :class="{
            [state]: true,
            'code-item': true,
            filled: code ? code[item - 1] : false,
            active: getActiveStatus(Number(item)),
            'first-item': firstPart[i] === 1,
            'last-item': firstPart[i] === firstPart[firstPart.length - 1],
          }"
        >
          {{ code[item - 1]?.toUpperCase() || placeholder }}
        </div>
      </div>

      <div v-if="hasSeparator" key="-" class="state separator">
        <div />
      </div>

      <div class="split-code--part">
        <div
          v-for="(item, i) in secondPart"
          :key="i"
          :class="{
            [state]: true,
            'code-item': true,
            filled: code ? code[item - 1] : false,
            active: getActiveStatus(Number(item)),
            'first-item': secondPart[i] === 4,
            'last-item': secondPart[i] === secondPart[secondPart.length - 1],
          }"
        >
          {{ code[item - 1]?.toUpperCase() || placeholder }}
        </div>
      </div>
    </div>

    <div v-if="!hideErrorMessage" class="error-text" :class="{ centered: isCentered }">
      <AppText v-if="errorMessage" size="13px" :line-height="1.5" color="var(--color-error)">
        {{ errorMessage }}
      </AppText>
    </div>
  </div>
</template>

<script setup>
import {
  computed, onMounted, ref, watch,
} from 'vue';
import { maskito as vMaskito } from '@maskito/vue';
import { useTimeoutFn } from '@vueuse/core';

import { maskitoRules } from '@/common/data';
import { keys, useKeyboardEvent } from '@/composables/useKeyboardEvent';
import { focusNextElement, focusPreviousElement } from '@/utils/focus';

const props = defineProps({
  type: {
    type: String,
    default: 'normal',
    validator: (value) => ['normal', 'dashed', 'seed'].includes(value),
  },
  state: {
    type: String,
    default: '',
    validator: (value) => ['', 'error', 'success', 'disabled'].includes(value),
  },
  mask: {
    type: String,
    default: 'totp',
  },
  template: {
    type: String,
    default: '***-***',
    validator: (value) => /^[*-]+$/.test(value),
  },
  isCentered: {
    type: Boolean,
    default: false,
  },
  isAutoClearError: {
    type: Boolean,
    default: false,
  },
  errorMessage: {
    type: String,
    default: undefined,
  },
  hideErrorMessage: {
    type: Boolean,
    default: false,
  },
  isAutoFocusDisabled: {
    type: Boolean,
    default: false,
  },
  isContinuousFocus: {
    type: Boolean,
    default: false,
  },
});

const maskitoOptions = computed(() => maskitoRules[props.mask] || {});

onMounted(() => {
  if (!props.isAutoFocusDisabled) {
    hiddenInput.value.focus();
  }
});

const isFocused = ref(false);
const code = defineModel({ type: String, default: '' });

const hiddenInput = ref(null);

const onClick = () => {
  hiddenInput.value.focus();
};

const items = computed(() => {
  const arr = [];
  let i = 1;
  props.template.split('').forEach((item) => {
    if (item === '*') {
      arr.push(i);
      i += 1;
    } else {
      arr.push(item);
    }
  });
  return arr;
});

const hasSeparator = computed(() => props.template.includes('-'));
const arrayedTemplate = computed(() => items.value.join('').split('-'));
const firstPart = computed(() => arrayedTemplate.value && arrayedTemplate.value[0].split('').map((el) => Number(el)));
const secondPart = computed(() => arrayedTemplate.value[1] && arrayedTemplate.value[1].split('').map((el) => Number(el)));

const placeholder = computed(() => (props.mask === 'totp' ? '0' : 'X'));

const maxLength = computed(() => props.template?.replace(/-/g, '').length);

const getActiveStatus = (i) => isFocused.value && (
  (code.value.length === 0 && i === 1)
  || (code.value.length === maxLength.value && i === maxLength.value)
  || (code.value.length + 1 === i)
);

watch(() => props.state, (newVal) => {
  if (newVal === 'error' && props.isAutoClearError) {
    useTimeoutFn(() => {
      code.value = '      ';
    }, 2000);
    useTimeoutFn(() => {
      code.value = '';
    }, 2000.3);
  }
});

watch(() => code.value, (newVal) => {
  if (props.isContinuousFocus && newVal.length === maxLength.value) {
    console.log('focusNextElement');
    focusNextElement('.split-code-input');
  }
});

useKeyboardEvent(keys.BACKSPACE, () => {
  if (props.isContinuousFocus && isFocused.value && code.value.length === 0) {
    focusPreviousElement('.split-code-input');
  }
});
</script>

<style lang="scss" scoped>
.split-code-wrap {
  &.centered {
    @include flex-center;
    flex-direction: column;
  }

  .split-code {
    cursor: pointer;
    display: inline-flex;

    &--part {
      cursor: pointer;
      display: inline-flex;
    }

    &.normal {
      .split-code--part {
        gap: 8px;

        @include mq('mobile') {
          gap: 4px;
        }
      }

      .code-item {
        width: 50px;
        height: 46px;
        font-size: 17px;
        color: var(--color-CCCCCC);
        border: 1px solid var(--color-E5E5E5);
        border-radius: 6px;
        background-color: var(--color-white);
        @include flex-center;
        @include transition-base('all');

        &.filled {
          color: var(--color-black);
        }

        &.active:not(.success):not(.error):not(.disabled) {
          border-color: var(--color-primary);
        }

        &.success {
          background: var(--color-green-01);
          border-color: var(--color-green-01);
          color: var(--color-green);
        }

        &.error {
          background: var(--color-error-01);
          border-color: var(--color-error-01);
          color: var(--color-error);
        }

        &.disabled {
          background-color: var(--color-F7F7F7);
          border-color: var(--color-F7F7F7);
          color: var(--color-black-02);
        }

        @include mq('mobile') {
          width: 32px;
          height: 40px;
        }
      }
    }

    &.dashed {
      position: relative;
      .split-code--part {
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
        border-radius: 4px;
      }

      .code-item {
        font-size: 21px;
        width: 47px;
        height: 54px;
        border: 1px solid var(--color-black-01);
        color: var(--color-black-02);
        border-left: none;
        @include flex-center;

        &.filled {
          color: var(--color-black);
        }

        &.error {
          border-color: var(--color-error);
          color: var(--color-error);

          &.first-item {
            border-left: 1px solid var(--color-error);
          }
        }

        &.success {
          border-color: var(--color-green);
          color: var(--color-green);

          &.first-item {
            border-left: 1px solid var(--color-green);
          }
        }

        &.first-item {
          border-left: 1px solid var(--color-black-01);
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }

        &.last-item {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }

        &.active:not(.success):not(.error):not(.disabled) {
          position: relative;
          &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            border: 1px solid var(--color-primary);
          }

          &.first-item:after {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          }

          &.last-item:after {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
          }
        }
      }

      .separator {
        width: 25px;
        height: 54px;
        @include flex-center;

        & > div {
          width: 9px;
          height: 2px;
          background-color: var(--color-black-02);
        }
      }
    }

    &.seed {
      border: 1px solid var(--color-black-01);
      min-width: 180px;
      height: 50px;
      font-size: 16px;
      text-transform: lowercase;
      border-radius: 12px;
      @include font-mono;
      @include flex-center;

      @at-root .active#{&} {
        border-color: var(--color-primary);
      }

      @at-root .error#{&} {
        border-color: var(--color-error);
      }

      .code-item:not(.filled) {
        color: var(--color-black-02);
      }
      .code-item.filled {
        color: var(--color-black);
      }

      .code-item {
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;

        &.active:not(.success):not(.error):not(.disabled) {
          border-bottom: 1px solid var(--color-black);
        }
      }
    }
  }

  .error-text {
    width: 100%;
    height: 40px;
    padding-top: 8px;

    &.centered {
      @include flex-center;
    }
  }
}
</style>
