import { useFetch } from '@/api';

export const authAsAdmin = async ({ username, totpCode }) => {
  const { axiosWrapper } = useFetch();
  const { data, isSuccess, errorObject } = await axiosWrapper({
    type: 'post',
    url: '/admin/login',
    params: { username, totpCode },
    isDefaultErrorNotificationEnabled: false,
  });
  return { data, isSuccess, errorObject };
};

export const getAdminDeposits = async () => {
  const { axiosWrapper } = useFetch();
  await axiosWrapper({
    type: 'get',
    url: '/admin/deposits/list',
    params: {
      page: 1,
      perPage: 25,
      search: '',
    },
  });
};
