<template>
  <ResponsiveBlockWrapper width="430px">
    <AuthCardWrapper>
      <AppIcon
        name="logo-black"
        is-img-tag
        width="68px"
        height="33px"
        :style="{ 'margin-bottom': apiError ? '30px' : '54px' }"
      />

      <AppText v-if="apiError" class="font-medium" color="var(--color-error)" mb="30px">
        {{ apiError }}
      </AppText>

      <ActionForm
        :request-fields="requestFields"
        :validation-schema="validationSchema"
        :response-errors="fieldsErrors"
        @validate="onPassedValidation"
        @error="onValidationError"
      >
        <template #default="{ errors, action }">
          <FInput
            v-model="requestFields.username"
            has-prefix
            :maxlength="25"
            placeholder="Username"
            :has-error="Boolean(apiError?.length) && requestFields.username?.length"
            :validation-error="errors.username"
            autofocus
            tabindex="0"

            :class="{
              'is-login-processing': isLoading,
            }"
          >
            <template #prefix>
              <AppIcon
                name="user"
                size="18px"
                fill="var(--color-black-04)"
              />
            </template>
          </FInput>

          <SplitInput
            v-model="requestFields.totpCode"
            type="dashed"
            :state="totpInputState"
            is-auto-clear-error
            style="margin-top: 20px;"
            hide-error-message
            :class="{
              'is-login-processing': isLoading,
            }"
          />

          <FButton
            is-full-width
            :loading="isLoading"
            type="black"
            style="margin-top: 20px;"
            @click="action"
          >
            Login
          </FButton>
        </template>
      </ActionForm>
    </AuthCardWrapper>
  </ResponsiveBlockWrapper>
</template>

<script>
import { ref } from 'vue';

import ResponsiveBlockWrapper from '@/components/Animation/ResponsiveBlockWrapper.vue';
import SplitInput from '@/components/Inputs/SplitInput.vue';

import { router } from '@/router';
import {
  ActionForm, codeRule, adminNameRule, useValidation, yup,
} from '@/validation';

import AuthCardWrapper from '../AuthCardWrapper.vue';

import { authAsAdmin, getAdminDeposits } from './api';

export default {
  name: 'AdminLogin',
  components: {
    SplitInput,
    ActionForm,
    AuthCardWrapper,
    ResponsiveBlockWrapper,
  },
  emits: ['submit'],
  setup() {
    const { initState, fieldsErrors, clearState } = useValidation();

    const fields = {
      username: '',
      totpCode: '',

    };

    const apiError = ref('');

    const validationSchema = yup.object().shape({
      username: adminNameRule,
      totpCode: codeRule,
    });

    const totpInputState = ref('');
    const { requestFields } = initState(fields);

    const resetFieldsState = () => {
      setTimeout(() => {
        apiError.value = '';
        totpInputState.value = '';
        clearState();
      }, 2000);
    };

    const isLoading = ref(false);
    const onPassedValidation = async () => {
      isLoading.value = true;
      const { isSuccess, errorObject } = await authAsAdmin({
        username: requestFields.username,
        totpCode: requestFields.totpCode,
      });
      if (isSuccess) {
        await getAdminDeposits();
        router.push('/sales');
      } else {
        totpInputState.value = 'error';
        if (errorObject?.error?.data?.message === 'INVALID_CREDENTIALS') {
          apiError.value = 'Invalid Credentials';
        } else if (errorObject?.error?.data?.message === 'ADMIN_IS_SUSPENDED') {
          apiError.value = 'Account Unavailable';
          requestFields.totpCode = '';
          totpInputState.value = '';
        } else if (errorObject?.error?.data?.message === 'REQUEST_BLOCKED') {
          apiError.value = 'Too many attempts';
        }

        resetFieldsState();
        setTimeout(() => {
          isLoading.value = false;
        }, 200);
      }
    };

    const onValidationError = (incomingErrors) => {
      if (incomingErrors.totpCode) {
        totpInputState.value = 'error';
      }
      setTimeout(() => {
        apiError.value = '';
        totpInputState.value = '';
      }, 2000);
    };

    return {
      isLoading,

      requestFields,
      validationSchema,
      fieldsErrors,
      onPassedValidation,
      onValidationError,
      apiError,
      totpInputState,
    };
  },
};
</script>
